/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var configRouter = {
  path: '/config',
  component: Layout,
  redirect: 'noredirect',
  name: 'config',
  meta: {
    title: '参数管理',
    icon: 'order-setting'
  },
  children: [{
    path: 'shopCate',
    component: function component() {
      return import('@/views/config/shopCate/index');
    },
    name: 'configShopCate',
    meta: {
      title: '电商类别设置',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'member',
    component: function component() {
      return import('@/views/config/memberGroup/index');
    },
    name: 'configMember',
    meta: {
      title: '会员组参数',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'addressLimit',
    component: function component() {
      return import('@/views/config/addressLimit/index');
    },
    name: 'configAddressLimit',
    meta: {
      title: '接单区域限制',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'shopTarget',
    component: function component() {
      return import('@/views/config/shopTarget/index');
    },
    name: 'configShopTarget',
    meta: {
      title: '标签类目设置',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'pointConfig',
    component: function component() {
      return import('@/views/config/point/index');
    },
    name: 'pointConfig',
    meta: {
      title: '积分规则设置',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'mission',
    component: function component() {
      return import('@/views/config/mission/index');
    },
    name: 'configMission',
    meta: {
      title: '佣金奖励设置',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'mission/edit/:id(\\d+)',
    component: function component() {
      return import('@/views/config/mission/detail');
    },
    name: 'editConfigMission',
    meta: {
      title: '设置佣金组别比例',
      role: []
    },
    hidden: true
  }, {
    path: 'rechargeCard',
    component: function component() {
      return import('@/views/config/rechargeCard/index');
    },
    name: 'configRechargeCard',
    meta: {
      title: '充值卡设置',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'configScanBlack',
    component: function component() {
      return import('@/views/config/scanBlack/index');
    },
    name: 'configScanBlack',
    meta: {
      title: '黑号自动清理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'gzh',
    component: function component() {
      return import('@/views/config/gzh/index');
    },
    name: 'configGzh',
    meta: {
      title: '微信短信通知',
      icon: 'list',
      role: ['admin']
    }
  },
  /* {
    path: 'generalAgent',
    component: () => import('@/views/config/agent/index'),
    name: 'configGeneralAgent',
    meta: { title: '总代理佣金设置', icon: 'list', role: ['admin'] }
  }, */
  {
    path: 'buySeller',
    component: function component() {
      return import('@/views/config/buySeller/index');
    },
    name: 'configBuySeller',
    meta: {
      title: '绑定买卖号设置',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'account',
    component: function component() {
      return import('@/views/config/account/index');
    },
    name: 'configAccount',
    meta: {
      title: '系统收款账户',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'taskAdvances',
    component: function component() {
      return import('@/views/tmenu');
    },
    name: 'configTaskAdvances',
    meta: {
      title: '垫付任务参数',
      icon: 'list',
      role: ['admin']
    },
    noComponent: true,
    children: [{
      path: 'taskBuyer',
      component: function component() {
        return import('@/views/config/taskAdvances/taskBuyer/index');
      },
      name: 'configTaskAdvancesTaskBuy',
      meta: {
        title: '接手任务参数',
        icon: 'list',
        role: ['admin']
      }
    }, {
      path: 'taskSeller',
      component: function component() {
        return import('@/views/config/taskAdvances/taskSeller/index');
      },
      name: 'configTaskAdvancesTaskSeller',
      meta: {
        title: '发布任务参数',
        icon: 'list',
        role: ['admin']
      }
    }, {
      path: 'taskFee',
      component: function component() {
        return import('@/views/config/taskAdvances/taskFee/index');
      },
      name: 'configTaskAdvancesTaskFee',
      meta: {
        title: '发布资费设置',
        icon: 'list',
        role: ['admin']
      }
    }, {
      path: 'taskReward',
      component: function component() {
        return import('@/views/config/taskAdvances/taskReward/index');
      },
      name: 'configTaskAdvancesTaskReward',
      meta: {
        title: '任务额外奖励设置',
        icon: 'list',
        role: ['admin']
      }
    }, {
      path: 'taskWarn',
      component: function component() {
        return import('@/views/config/taskAdvances/taskWarning/index');
      },
      name: 'configTaskAdvancesTaskWarn',
      meta: {
        title: '任务安全预警设置',
        icon: 'list',
        role: ['admin']
      }
    }, {
      path: 'taskTimeout',
      component: function component() {
        return import('@/views/config/taskAdvances/taskTimeout/index');
      },
      name: 'configTaskAdvancesTaskTimeout',
      meta: {
        title: '任务超时设置',
        icon: 'list',
        role: ['admin']
      }
    }]
  }, {
    path: 'taskTraffic',
    component: function component() {
      return import('@/views/tmenu');
    },
    name: 'configTaskTraffic',
    meta: {
      title: '浏览任务参数',
      icon: 'system-menu',
      role: ['admin']
    },
    noComponent: true,
    children: [{
      path: 'taskBuyer',
      component: function component() {
        return import('@/views/config/taskTraffic/taskBuyer/index');
      },
      name: 'configTaskTrafficTaskBuy',
      meta: {
        title: '接手任务参数',
        icon: 'shu',
        role: ['admin']
      }
    }, {
      path: 'taskSeller',
      component: function component() {
        return import('@/views/config/taskTraffic/taskSeller/index');
      },
      name: 'configTaskTrafficTaskSeller',
      meta: {
        title: '发布任务参数',
        icon: 'shu',
        role: ['admin']
      }
    }, {
      path: 'taskFee',
      component: function component() {
        return import('@/views/config/taskTraffic/taskFee/index');
      },
      name: 'configTaskTrafficTaskFee',
      meta: {
        title: '发布资费设置',
        icon: 'shu',
        role: ['admin']
      }
    }, {
      path: 'taskReward',
      component: function component() {
        return import('@/views/config/taskTraffic/taskReward/index');
      },
      name: 'configTaskTrafficTaskReward',
      meta: {
        title: '任务额外奖励设置',
        icon: 'shu',
        role: ['admin']
      }
    }, {
      path: 'taskTimeout',
      component: function component() {
        return import('@/views/config/taskTraffic/taskTimeout/index');
      },
      name: 'configTaskTrafficTaskTimeout',
      meta: {
        title: '任务超时设置',
        icon: 'shu',
        role: ['admin']
      }
    }]
  }]
};
export default configRouter;