import _objectSpread from "E:/chenwf/code/zsb/blackant/code/master/blackant_ui_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
import { countVerifyNum } from '@/api/index';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routes', 'sidebar'])), {}, {
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    openPath: function openPath() {
      var data = this.$store.state.user.menus;
      var list = data.map(function (item) {
        if (item.url == '/buyVerify' || item.url == '/sellerVerify' || item.url == '/member' || item.url == '/taskAdvances') {
          return item.url;
        }
      });
      return list;
    }
  }),
  data: function data() {
    return {
      verifyData: {},
      verifyDataNum: ''
    };
  },
  created: function created() {
    this.count();
  },
  watch: {
    $route: function $route(_ref) {
      var path = _ref.path,
        meta = _ref.meta;
      var self = this;
      // self.count();
    }
  },
  methods: {
    count: function count() {
      var _this = this;
      this.verifyDataNum = '';
      countVerifyNum().then(function (response) {
        var result = response.data;
        _this.verifyData = result;
        var verifyDataNum = {
          'taobaoVerify': _this.verifyData.taobaoNum,
          'huabeiVerify': _this.verifyData.huabeiNum,
          'jdVerify': _this.verifyData.jdNum,
          'addressVerify': _this.verifyData.addressNum,
          'baitiaoVerify': _this.verifyData.baitiaoNum,
          'pddVerify': _this.verifyData.pddNum,
          'dyVerify': _this.verifyData.dyNum,
          'complaintVerify': _this.verifyData.complaintVerifyNum,
          'sellerTaskVerify': _this.verifyData.verifyTaskNum,
          'sellerVerify': _this.verifyData.sellerVerifyNum,
          'rejectUnPayVerify': _this.verifyData.unpayRejectTaskNum,
          'rejectUnReceiveVerify': _this.verifyData.unRecieveRejectTaskNum,
          'realnameVerify': _this.verifyData.idcardVerifyNum
        };
        _this.verifyDataNum = verifyDataNum;
      });
    }
  }
};