/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var sellerVerifyRouter = {
  path: '/sellerVerify',
  component: Layout,
  redirect: 'noredirect',
  name: 'sellerVerify',
  meta: {
    title: '商家审核',
    icon: 'peoples'
  },
  children: [{
    path: 'complaintVerify',
    component: function component() {
      return import('@/views/sellerVerify/complaint/index');
    },
    name: 'complaintVerify',
    meta: {
      title: '申诉处理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'sellerTaskVerify',
    component: function component() {
      return import('@/views/sellerVerify/task/index');
    },
    name: 'sellerTaskVerify',
    meta: {
      title: '商家任务审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'sellerVerify',
    component: function component() {
      return import('@/views/sellerVerify/seller/index');
    },
    name: 'sellerVerify',
    meta: {
      title: '商家店铺审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'rejectUnPayVerify',
    component: function component() {
      return import('@/views/sellerVerify/unPay/index');
    },
    name: 'rejectUnPayVerify',
    meta: {
      title: '未支付驳回审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'rejectUnReceiveVerify',
    component: function component() {
      return import('@/views/sellerVerify/unReceive/index');
    },
    name: 'rejectUnReceiveVerify',
    meta: {
      title: '未好评驳回审核',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default sellerVerifyRouter;