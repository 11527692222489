/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var buyerVerifyRouter = {
  path: '/buyVerify',
  component: Layout,
  redirect: 'noredirect',
  name: 'buyVerify',
  meta: {
    title: '买号审核',
    icon: 'peoples'
  },
  children: [{
    path: 'taobaoVerify',
    component: function component() {
      return import('@/views/buyVerify/taobao/index');
    },
    name: 'taobaoVerify',
    meta: {
      title: '淘宝号审核',
      icon: 'list',
      role: ['admin']
    }
  },
  /* {
     path: 'idCard',
     component: () => import('@/views/buyVerify/idCard/index'),
     name: 'buyerIdCard',
     meta: { title: '身份认证', icon:'list', role: ['admin'] }
   }, */
  {
    path: 'huabeiVerify',
    component: function component() {
      return import('@/views/buyVerify/huabei/index');
    },
    name: 'huabeiVerify',
    meta: {
      title: '花呗审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'jdVerify',
    component: function component() {
      return import('@/views/buyVerify/jd/index');
    },
    name: 'jdVerify',
    meta: {
      title: '京东号审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'addressVerify',
    component: function component() {
      return import('@/views/buyVerify/address/index');
    },
    name: 'addressVerify',
    meta: {
      title: '修改收货信息审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'baitiaoVerify',
    component: function component() {
      return import('@/views/buyVerify/baitiao/index');
    },
    name: 'baitiaoVerify',
    meta: {
      title: '白条审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'pddVerify',
    component: function component() {
      return import('@/views/buyVerify/pdd/index');
    },
    name: 'pddVerify',
    meta: {
      title: '拼多多账号审核',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'dyVerify',
    component: function component() {
      return import('@/views/buyVerify/dy/index');
    },
    name: 'dyVerify',
    meta: {
      title: '抖音账号审核',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default buyerVerifyRouter;