/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';

//系统管理路由配置
var systemRouter = {
  path: '/system',
  component: Layout,
  redirect: '/system/admin',
  name: 'system',
  meta: {
    title: '系统管理',
    icon: 'system'
  },
  children: [{
    path: 'admin',
    component: function component() {
      return import('@/views/system/admin/index');
    },
    name: 'adminTable',
    meta: {
      title: '管理员',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'adminType',
    component: function component() {
      return import('@/views/system/adminType/index');
    },
    name: 'adminTypeTable',
    meta: {
      title: '角色管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'params',
    component: function component() {
      return import('@/views/system/params/index');
    },
    name: 'params',
    meta: {
      title: '系统参数',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'modParams',
    component: function component() {
      return import('@/views/system/params/modParams');
    },
    name: 'modParams',
    meta: {
      title: 'APP更新',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'menuList',
    component: function component() {
      return import('@/views/system/menu/index');
    },
    name: 'menuList',
    meta: {
      title: '菜单管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'center',
    component: function component() {
      return import('@/views/system/center/index');
    },
    name: 'adminCenter',
    meta: {
      title: '个人中心',
      noCache: true
    },
    hidden: true
  }]
};
export default systemRouter;