/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var substationRouter = {
  path: '/substation',
  component: Layout,
  redirect: 'noredirect',
  name: 'substation',
  meta: {
    title: '分站管理',
    icon: 'peoples'
  },
  children: [{
    path: 'station',
    component: function component() {
      return import('@/views/substation/index');
    },
    name: 'station',
    meta: {
      title: '分站列表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'subWithdraw',
    component: function component() {
      return import('@/views/substation/subWithdraw/index');
    },
    name: 'subWithdraw',
    meta: {
      title: '分站提现',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'agentWithdraw',
    component: function component() {
      return import('@/views/substation/agentWithdraw/index');
    },
    name: 'agentWithdraw',
    meta: {
      title: '代理提现',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'stationReport',
    component: function component() {
      return import('@/views/substation/report/index');
    },
    name: 'stationReport',
    meta: {
      title: '分站日报',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default substationRouter;