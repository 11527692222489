/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var buySellerRouter = {
  path: '/buySeller',
  component: Layout,
  redirect: 'noredirect',
  name: 'buySeller',
  meta: {
    title: '买卖号管理',
    icon: 'theme'
  },
  children: [{
    path: 'buyer',
    component: function component() {
      return import('@/views/buySeller/buyer/index');
    },
    name: 'buyer',
    meta: {
      title: '买号管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'seller',
    component: function component() {
      return import('@/views/buySeller/seller/index');
    },
    name: 'seller',
    meta: {
      title: '掌柜号管理',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default buySellerRouter;