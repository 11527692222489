/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var taskTrafficRouter = {
  path: '/taskTraffic',
  component: Layout,
  redirect: 'noredirect',
  name: 'taskTraffic',
  meta: {
    title: '浏览任务',
    icon: 'search'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/taskTraffic/index/index');
    },
    name: 'taskTrafficList',
    meta: {
      title: '任务列表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'order',
    component: function component() {
      return import('@/views/taskTraffic/index/order');
    },
    name: 'taskTrafficOrderList',
    meta: {
      title: '订单列表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'complaint',
    component: function component() {
      return import('@/views/taskTraffic/complaint/index');
    },
    name: 'taskTrafficComplaint',
    meta: {
      title: '申诉管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'template',
    component: function component() {
      return import('@/views/taskTraffic/template/index');
    },
    name: 'taskTrafficTemplate',
    meta: {
      title: '任务模板',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'colums',
    component: function component() {
      return import('@/views/taskTraffic/colum/index');
    },
    name: 'taskTrafficColums',
    meta: {
      title: '任务区栏目',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default taskTrafficRouter;