/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var memberRouter = {
  path: '/report',
  component: Layout,
  redirect: '/platform/index',
  name: 'report',
  alwaysShow: true,
  meta: {
    title: '报表统计',
    icon: 'table'
  },
  children: [{
    path: 'platform',
    component: function component() {
      return import('@/views/report/platform/index');
    },
    name: 'platformList',
    meta: {
      title: '财务报表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'member',
    component: function component() {
      return import('@/views/report/member/index');
    },
    name: 'memberList',
    meta: {
      title: '商家报表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'seller',
    component: function component() {
      return import('@/views/report/seller/index');
    },
    name: 'sellerList',
    meta: {
      title: '店铺报表',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default memberRouter;