var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.$route.path,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                "unique-opened": true,
                "default-openeds": _vm.openPath,
                mode: "vertical",
              },
            },
            _vm._l(_vm.permission_routes, function (route) {
              return _vm.verifyDataNum != ""
                ? _c("sidebar-item", {
                    key: route.path,
                    attrs: {
                      item: route,
                      "base-path": route.path,
                      verifyData: _vm.verifyDataNum,
                    },
                  })
                : _vm._e()
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }