/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var fundRouter = {
  path: '/fund',
  component: Layout,
  redirect: 'noredirect',
  name: 'fund',
  meta: {
    title: '财务管理',
    icon: 'money'
  },
  children: [{
    path: 'withdraw',
    component: function component() {
      return import('@/views/fund/withdraw/index');
    },
    name: 'withdraw',
    meta: {
      title: '提现管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'recharge',
    component: function component() {
      return import('@/views/fund/recharge/index');
    },
    name: 'recharge',
    meta: {
      title: '充值记录',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'account',
    component: function component() {
      return import('@/views/fund/account/index');
    },
    name: 'account',
    meta: {
      title: '资金明细',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'point',
    component: function component() {
      return import('@/views/fund/point/index');
    },
    name: 'point',
    meta: {
      title: '积分明细',
      icon: 'list',
      role: ['admin']
    }
  },
  /* {
    path: 'publishPoint',
    component: () => import('@/views/fund/publishPoint/index'),
    name: 'publishPoint',
    meta: { title: '发布点明细', icon:'list', role: ['admin'] }
  }, */
  {
    path: 'spmission',
    component: function component() {
      return import('@/views/fund/spmission/index');
    },
    name: 'spmission',
    meta: {
      title: '推广佣金明细',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default fundRouter;