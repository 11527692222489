/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var memberRouter = {
  path: '/member',
  component: Layout,
  redirect: '/member/list',
  name: 'member',
  alwaysShow: true,
  meta: {
    title: '会员管理',
    icon: 'user'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/member/index/index');
    },
    name: 'memberList',
    meta: {
      title: '会员列表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'detail',
    component: function component() {
      return import('@/views/member/index/detailInfo');
    },
    name: 'memberDetailInfo',
    meta: {
      title: '会员详情',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'detail/:id(\\d+)',
    component: function component() {
      return import('@/views/member/index/detail');
    },
    name: 'editMember',
    meta: {
      title: '编辑会员详情',
      noCache: true,
      role: []
    },
    hidden: true
  }, {
    path: 'group',
    component: function component() {
      return import('@/views/member/group/index');
    },
    name: 'group',
    meta: {
      title: '会员组别',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'realname',
    component: function component() {
      return import('@/views/member/realname/index');
    },
    name: 'realnameVerify',
    meta: {
      title: '实名认证管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'bank',
    component: function component() {
      return import('@/views/member/bank/index');
    },
    name: 'bankVerify',
    meta: {
      title: '银行认证管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'block',
    component: function component() {
      return import('@/views/member/block/index');
    },
    name: 'block',
    meta: {
      title: '用户拉黑名单',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'systemBlack',
    component: function component() {
      return import('@/views/member/systemBlack/index');
    },
    name: 'systemBlack',
    meta: {
      title: '诈骗库',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'punishment',
    component: function component() {
      return import('@/views/member/punishment/index');
    },
    name: 'memberPunishment',
    meta: {
      title: '用户处罚管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'phonebook',
    component: function component() {
      return import('@/views/phonebook/index/index');
    },
    name: 'phonebook',
    meta: {
      title: '手机通讯录',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'phonebook/viewLog/:id(\\d+)',
    component: function component() {
      return import('@/views/phonebook/log/index');
    },
    name: 'viewPhonebookLog',
    meta: {
      title: '查看通讯录',
      noCache: true,
      role: []
    },
    hidden: true
  }, {
    path: 'phonebook/viewMsg/:id(\\d+)',
    component: function component() {
      return import('@/views/phonebook/message/index');
    },
    name: 'viewPhonebookMsg',
    meta: {
      title: '查看短信',
      noCache: true,
      role: []
    },
    hidden: true
  }, {
    path: 'log',
    component: function component() {
      return import('@/views/member/log/index');
    },
    name: 'memberLog',
    meta: {
      title: '会员日志',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default memberRouter;