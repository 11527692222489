import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import memberRouter from "./modules/member";
import fundRouter from "./modules/fund";
import buySellerRouter from "./modules/buySeller";
import taskAdvancesRouter from "./modules/taskAdvances";
import taskTrafficRouter from "./modules/taskTraffic";
import configRouter from "./modules/config";
import courierRouter from "./modules/courier";
import contentRouter from "./modules/content";
import systemRouter from "./modules/system";
import thirdRouter from "./modules/third";
import logRouter from "./modules/log";
import buyerVerifyRouter from "./modules/buyerVerify";
import sellerVerifyRouter from "./modules/sellerVerify";
import substationRouter from "./modules/substation";
import report from "./modules/report";

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/authredirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}];
export var asyncRoutes = [buyerVerifyRouter, sellerVerifyRouter, memberRouter, fundRouter, buySellerRouter, taskAdvancesRouter, taskTrafficRouter, configRouter, courierRouter, substationRouter, contentRouter, systemRouter, logRouter, thirdRouter, report, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;