/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var courierRouter = {
  path: '/courier',
  component: Layout,
  redirect: 'noredirect',
  name: 'courier',
  meta: {
    title: '快递订购',
    icon: 'live'
  },
  children: [
  /* {
     path: 'gift',
     component: () => import('@/views/content/banner/index'),
     name: 'courierGift',
     meta: { title: '礼品快递管理', icon:'list', role: ['admin'] }
   }, */
  {
    path: 'source',
    component: function component() {
      return import('@/views/gift/source/index');
    },
    name: 'courierSource',
    meta: {
      title: '礼品货源数据',
      icon: 'list',
      role: ['admin']
    }
  }
  /* {
    path: 'giftPrice',
    component: () => import('@/views/content/banner/index'),
    name: 'courierGiftPrice',
    meta: { title: '礼品价格设置', icon:'list', role: ['admin'] }
  },
  {
    path: 'emptyBag',
    component: () => import('@/views/content/banner/index'),
    name: 'courierEmptyBag',
    meta: { title: '空包快递管理', icon:'list', role: ['admin'] }
  },
  {
    path: 'importEmptyBag',
    component: () => import('@/views/content/banner/index'),
    name: 'importEmptyBag',
    meta: { title: '导入空包快递', icon:'list', role: ['admin'] }
  },
  {
    path: 'emptyBagSource',
    component: () => import('@/views/content/banner/index'),
    name: 'emptyBagSource',
    meta: { title: '空包货源数据', icon:'list', role: ['admin'] }
  },
  {
    path: 'emptyBagPrice',
    component: () => import('@/views/content/banner/index'),
    name: 'emptyBagPrice',
    meta: { title: '空包价格设置', icon:'list', role: ['admin'] }
  },
  {
    path: 'address',
    component: () => import('@/views/content/banner/index'),
    name: 'courierAddress',
    meta: { title: '发货地址管理', icon:'list', role: ['admin'] }
  }, */]
};
export default courierRouter;