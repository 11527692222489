/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var taskAdvancesRouter = {
  path: '/taskAdvances',
  component: Layout,
  redirect: 'noredirect',
  name: 'taskAdvances',
  meta: {
    title: '垫付任务',
    icon: 'nested'
  },
  children: [{
    path: 'verify',
    component: function component() {
      return import('@/views/taskAdvances/verify/index');
    },
    name: 'taskAdvancesParentList',
    meta: {
      title: '任务列表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/taskAdvances/index/index');
    },
    name: 'taskAdvancesList',
    meta: {
      title: '订单列表',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'cancel',
    component: function component() {
      return import('@/views/taskAdvances/cancel/index');
    },
    name: 'taskCancelList',
    meta: {
      title: '撤销订单',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'overtimeTask',
    component: function component() {
      return import('@/views/taskAdvances/overtime/index');
    },
    name: 'overtimeTask',
    meta: {
      title: '返款超时单',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'complaint',
    component: function component() {
      return import('@/views/taskAdvances/complaint/index');
    },
    name: 'taskAdvancesComplaint',
    meta: {
      title: '申诉管理',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'comment',
    component: function component() {
      return import('@/views/taskAdvances/comment/index');
    },
    name: 'taskAdvancesComment',
    meta: {
      title: '信用评价',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'taskSendLog',
    component: function component() {
      return import('@/views/taskAdvances/sendLog/index');
    },
    name: 'taskSendLog',
    meta: {
      title: '派单查询',
      icon: 'list',
      role: ['admin']
    }
  }, {
    path: 'colums',
    component: function component() {
      return import('@/views/taskAdvances/colum/index');
    },
    name: 'taskAdvancesColums',
    meta: {
      title: '任务区栏目',
      icon: 'list',
      role: ['admin']
    }
  }]
};
export default taskAdvancesRouter;